var _singleton_pager = null;

class Pager{

    constructor(){
        let pathname = window.location.pathname;
        if(pathname[pathname.length-1] !== '/'){
            pathname = pathname.substr(0,pathname.lastIndexOf("/")+1);
        }
        this.url_without_file = window.location.protocol+"//"+window.location.host + pathname;

		this.regex = new RegExp('\{\{ ?(.+;) ?\}\}','g');

        this.pageChanged = this.pageChanged.bind(this);
        this.pageChange = this.pageChange.bind(this);
		// this.manageLoading = this.manageLoading.bind(this);

        window.onhashchange = this.pageChange;
        this.pageChange();
    }

    getHash(){
        this.hash = window.location.hash;
        if(!this.hash){
            this.hash = window.location.hash = "about";
        }
    }

    loadPage(){
        $("body > .loading-overlay").removeClass("hidden");
        let link = this.url_without_file+"pages/"+this.hash.substr(1)+".html?_='" + new Date().getTime();

        var r = this.regex;

        // this.timeoutLoading = setTimeout(this.manageLoading,5000);

        $.get(link)
        .done(function(data) {
			// clearTimeout(Pager().GetPager().timeoutLoading);
            let result = -1;
            // let result = data.match(r);
            while(result != null){
                result = r.exec(data);
                if(result ===null){ break;}
				data = data.replace(result[0],eval(result[1]));
            }
            $("#current-page").html(data);
            $("body > .loading-overlay").addClass("hidden");
            Pager.GetPager().pageChanged();
        })
        .fail(function() {
			// $("body > .loading-overlay").addClass("hidden");
        })
    }

    pageChange(){
        this.getHash();
        this.loadPage();
    }

	pageChanged(){
        let lis = document.querySelectorAll("#menu ul li"), i;
        for(i=0;i<lis.length;i++){
            let li = lis[i];
            let child = $(li).children()[0];
            let current_hash = child.hash;

            if(current_hash == this.hash){
				li.className = "active";
            }else{
				li.className = "";
            }
        }
	}

    // manageLoading(){
    //     alert("Page failed to load.");
    //     clearTimeout(this.timeoutLoading);
    // }

    static GetPager(){
        if(_singleton_pager == null){
            _singleton_pager = new Pager();
        }
        return _singleton_pager;
    }
}
function LoadPager(){
    Pager.GetPager();
}
function ChangePageTo(hash){
    Pager.GetPager().setPage(hash);
}
